import { useState } from "react"

import { MTextField, TMTextFieldProps } from "src/ui/MTextField/MTextField"

import {
  ISettingContainerBaseProps,
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "./SettingContainer"

export function SettingText({
  title,
  description,
  value,
  onSave,
  textFieldProps,
  ...props
}: ISettingContainerBaseProps & {
  textFieldProps?: TMTextFieldProps
  onSave: (newVal: string) => TSettingContainerOnSaveReturnType
  value: string
}) {
  const [saveValue, setSaveValue] = useState(value)

  return (
    <SettingContainer
      title={title}
      description={description}
      onSave={async () => {
        return await onSave(String(saveValue))
      }}
      displayValue={String(value)}
      InputComponent={({ saving }) => (
        <Input
          valueInitial={value}
          setSaveValue={setSaveValue}
          disabled={saving}
          {...textFieldProps}
        />
      )}
      {...props}
    />
  )
}

function Input({
  valueInitial,
  setSaveValue,
  ...textFieldProps
}: {
  valueInitial: string
  setSaveValue(newVal: string): void
} & TMTextFieldProps) {
  const [_value, _setValue] = useState(valueInitial)
  return (
    <MTextField
      value={_value}
      onChange={(value) => {
        _setValue(value)
        setSaveValue(value)
      }}
      {...textFieldProps}
    />
  )
}
